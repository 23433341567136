import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Home from './home';
import WebIma from './web-ima';
import WebMirai from './web-mirai';

// Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import '../css/swiper.css';

function Top() {
  return (
    <Swiper
      className='mySwiper'
      modules={[Navigation]}
      initialSlide={1}
      navigation
    >
      <SwiperSlide className='webStyle'>
        <WebIma />
      </SwiperSlide>
      <SwiperSlide className='homeStyle'>
        <Home />
      </SwiperSlide>
      <SwiperSlide className='webStyle'>
        <WebMirai />
      </SwiperSlide>
    </Swiper>
  );
}

export default Top;
