import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import useInterval from 'use-interval';
// import { useModal } from 'react-hooks-use-modal';
import style from '../css/web.module.css';
// import Nav from './nav.js';
import Tree from './tree';
import Bubble from './bubble.js';

const pageTitle = 'みらいの木';
const pageId = 'mirai';
const apiUrl = process.env.REACT_APP_API_URL;
const authName = process.env.REACT_APP_AUTH_NAME;
const authPswd = process.env.REACT_APP_AUTH_PSWD;

// 更新秒数 msec
let intervalMsec = 10000;
// let intervalMsec = null; // 更新停止

export default function SpaceMirai() {
  document.title = pageTitle;
  // bodyにclassを付与
  document.body.classList.remove('home');
  document.body.classList.add('web');

  const [post, setPost] = useState(null);

  useInterval(() => {
    axios
      .get(`${apiUrl}/api/${pageId}`, {
        auth: {
          username: authName,
          password: authPswd,
        },
      })
      .then((response) => {
        setPost(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, intervalMsec);

  // 新しい投稿が公開されたか判定 （描画エフェクト用）
  const prevPost = usePrevious(post);

  return (
    <div className={`${pageId}`}>
      {/* <Nav></Nav> */}
      <Tree post={post} pageId={pageId} pageTitle={pageTitle}></Tree>
      {post && <Bubble post={post} prevPost={prevPost}></Bubble>}
    </div>
  );
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
