import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import useInterval from 'use-interval';
import { useModal } from 'react-hooks-use-modal';
import style from '../css/web.module.css';
import Nav from './nav.js';
import OpenBtn from './open-btn.js';
import Tree from './tree';
import Bubble from './bubble.js';

const pageTitle = 'いまの木';
const pageId = 'ima';
const hashTags = process.env.REACT_APP_HASH_TAGS;
const apiUrl = process.env.REACT_APP_API_URL;
const authName = process.env.REACT_APP_AUTH_NAME;
const authPswd = process.env.REACT_APP_AUTH_PSWD;

// 更新秒数 msec
let intervalMsec = 60000;
// let intervalMsec = null; // 更新停止

export default function WebIma() {
  document.title = pageTitle;
  // bodyにclassを付与
  // document.body.classList.remove('home');
  // document.body.classList.add('web');

  const [post, setPost] = useState(null);
  const [message, setMessage] = useState('');
  const [Modal, open, close, isOpen] = useModal('root', {
    closeOnOverlayClick: false,
  });

  React.useEffect(() => {
    getPosts();
  }, []);

  useInterval(() => {
    getPosts();
  }, intervalMsec);

  const getPosts = () => {
    console.log('getPosts ima');
    axios
      .get(`${apiUrl}/api/${pageId}`, {
        auth: {
          username: authName,
          password: authPswd,
        },
      })
      .then((response) => {
        setPost(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // 新しい投稿が公開されたか判定 （描画エフェクト用）
  const prevPost = usePrevious(post);

  return (
    <>
      <OpenBtn openModal={open} pageId={pageId} pageTitle={pageTitle}></OpenBtn>
      <Nav></Nav>
      <Tree post={post} pageId={pageId} pageTitle={pageTitle}></Tree>
      {post && <Bubble post={post} prevPost={prevPost}></Bubble>}
      <Modal>
        <ModalContent
          message={message}
          setMessage={setMessage}
          isOpen={isOpen}
          closeModal={close}
        ></ModalContent>
      </Modal>
    </>
  );
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function ModalContent(props) {
  const { message, setMessage, isOpen, closeModal } = props;
  const [result, setResult] = useState(false);

  const handleChange = (e) => {
    setMessage(e.target.value);
    // console.log(message);
  };

  const handleSubmit = () => {
    // console.log(message);
    const data = {
      message_body: message,
    };

    if (!message) return;

    axios
      .post(`${apiUrl}/api/${pageId}/create`, data, {
        auth: {
          username: authName,
          password: authPswd,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setResult(true);
        }
      })
      .catch(function (error) {
        if (error.response.status === 422) {
          console.log('422', error.response.data.error_code);
          if (error.response.data.error_code === 'max') {
            alert('投稿は80文字までにしてください');
          } else if (error.response.data.error_code === 'ng_word') {
            alert('投稿に失敗しました。投稿内容をご確認ください。');
          }
        } else {
          alert('投稿に失敗しました。お手数ですがやり直しお願いします。');
        }
      });
  };

  const openSns = () => {
    window.open(
      `https://twitter.com/intent/tweet?&hashtags=${hashTags}&text=${message}&url=https://ima-mirai.shogakukan.co.jp`
    );
  };

  const onClose = () => {
    setMessage('');
    closeModal();
  };

  return (
    <div className={style.modal}>
      {(() => {
        if (result) {
          return (
            <div className={style.thanks}>
              <h2
                style={{
                  textAlign: 'center',
                  display: 'block',
                }}
              >
                <span style={{ color: '#9f000c' }}>
                  ご投稿ありがとうございました
                </span>
              </h2>
              <p style={{ textAlign: 'center' }}>
                Twitterにも投稿される方は
                <br />
                SNS投稿ボタンを押して進んでください
              </p>
              <p style={{ textAlign: 'center' }}>
                <span className={style.arrowDown}></span>
              </p>
              <button
                style={{ backgroundColor: '#1d9bf0', borderColor: '#1d9bf0' }}
                className={style.submitBtn}
                onClick={openSns}
              >
                SNS投稿
              </button>
            </div>
          );
        } else {
          return (
            <div style={{ textAlign: 'center' }}>
              <h2 style={{ textAlign: 'center', display: 'block' }}>
                <span>あなたの「いま」は？自由に書いてみよう！</span>
              </h2>
              <div style={{ textAlign: 'center' }}>
                <p>いまをどう思ってる？</p>
                <p>みらいのためにいましている事、できる事は？</p>
                <p>いまの自分に思う事、など。</p>
              </div>
              <h3 style={{ textAlign: 'center', display: 'block' }}>
                <span>投稿したいメッセージを書いてください</span>
              </h3>
              <textarea
                rows={5}
                placeholder='例：新社会人になりました。毎日緊張の連続。早く慣れたい。&#10;花をいっぱい育てている。&#10;コロナ禍での家事と仕事、がんばれてるかな？&#10;おいしいものが幸せの源。いろんな町の知らないお店探索がプチ贅沢。&#10;環境、健康のため出来るだけ歩く。'
                value={message}
                onChange={handleChange}
              />
              {message ? (
                <button className={style.submitBtn} onClick={handleSubmit}>
                  メッセージ投稿
                </button>
              ) : (
                <button className={style.disableBtn}>メッセージ投稿</button>
              )}
            </div>
          );
        }
      })()}
      <div className={style.notes}>
        <p>
          ＊頂いたメッセージは、今後弊社関連の媒体（雑誌、書籍、映像、ウェブサイト、SNS等）にて掲載される可能性があります。
          <br />
          ＊ご投稿にあたり、以下の行為はお控えください。以下に該当するコメントについては、管理者の方で削除します。
          <br />
          ・公序良俗に反する恐れのある投稿・第三者の著作権や肖像権、その他の権利を侵害する恐れのある投稿
          <br />
          ・第三者への誹謗中傷、またはプライバシーを侵害する恐れのある投稿・個人情報または個人を特定できる投稿
          <br />
          ・当社が悪質または不適切であると判断する投稿
        </p>
      </div>
      <span onClick={onClose} className={style.batsu}></span>
    </div>
  );
}
