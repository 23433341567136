import React from 'react';
// import { NavLink } from 'react-router-dom';
import { useSwiper } from 'swiper/react';
import style from '../css/web.module.css';
import '../css/swiper.css';
import logo from '../img/logo.jpg';

export default function Nav() {
  const swiper = useSwiper();
  // console.log('swiper.activeIndex:', swiper.activeIndex);

  const slideToIma = () => {
    console.log('slideToIma');
    swiper.slideTo(0);
  };

  const slideToHome = () => {
    console.log('slideToHOme');
    swiper.slideTo(1);
  };

  const slideToMirai = () => {
    console.log('slideToMirai');
    swiper.slideTo(2);
  };

  return (
    <nav className={style.nav}>
      <h1 onClick={slideToHome}>
        <img src={logo} alt='いまの木みらいの木' />
      </h1>
      <div onClick={slideToIma} className={style.link_ima}>
        いま
        <br />
        <span>の木</span>
      </div>
      <div onClick={slideToMirai} className={style.link_mirai}>
        みらい
        <br />
        <span>の木</span>
      </div>
    </nav>
  );
}
