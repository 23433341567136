import { Routes, Route } from 'react-router-dom';
import './css/reset.css';
// import './css/x.css';
import SpaceMirai from './components/space-mirai';
import SpaceIma from './components/space-ima';
import Top from './components/top';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Top />} />
        <Route path='/space-mirai' element={<SpaceMirai />} />
        <Route path='/space-ima' element={<SpaceIma />} />
      </Routes>
    </>
  );
}

export default App;
