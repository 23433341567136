import React from 'react';
import style from '../css/web.module.css';

export default function Bubble(props) {
  let items = [];
  let isFade = false;
  const posts = props.post.posts;

  // 新投稿が公開されたとき、bubbleをfadeIn表示
  if (
    props.prevPost !== null &&
    props.prevPost.responseId !== props.post.responseId
  ) {
    console.log('updated');
    isFade = true;
  }

  posts.forEach((post, i) => {
    // bubble エフェクト
    let animation = `${style.anime_random}`;
    if (i % 2 === 0) {
      animation = `${style.anime_fuwafuwa}`;
    } else if (i % 3 === 0) {
      animation = `${style.anime_zoom}`;
    } else if (i % 5 === 0) {
      animation = `${style.anime_random}`;
    }
    items.push(
      <div
        key={i}
        className={`${style.bubble} ${isFade && style.fadeIn} ${animation} ${
          style[`msg_${i}`]
        }`}
      >
        <span>{post.body}</span>
      </div>
    );
  });

  return items;
}
