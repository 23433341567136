import React from 'react';
import style from '../css/web.module.css';
import Nav from './nav.js';
import subTitle from '../img/100th.png';
import mainTitle from '../img/home-title.png';

const pageTitle = 'いまの木みらいの木';

export default function Home() {
  document.title = pageTitle;
  // bodyにclassを付与
  // document.body.classList.remove('web');
  // document.body.classList.add('home');

  return (
    <div className={style.home}>
      <Nav></Nav>
      <div className={style.home_container}>
        <img alt={pageTitle} src={subTitle} className={style.sub_title} />
        <img alt={pageTitle} src={mainTitle} className={style.main_title} />
        <div className={style.message}>
          <p>
            小学館は読者や地域のみなさまに支えられ、今年創立100周年を迎えました。
          </p>
          <p>私たちには、ずっと大切にしてきた想いがあります。</p>
          <p>
            「出版物は、人の心に良い方向を生み出す、何らかの小さな種子をまくことができます。
          </p>
          <p>その種子はやがて人生の中で大きな実となっていきます」</p>
          <p>「花開く種子をまく仕事、それが出版です」</p>
          <p>
            私たちは100年もの間、様々な出版物を通して、種子をまいてきました。
          </p>
          <p>そしてこれからも、みらいを見据え、新しい種をまいていきたい。</p>
          <p>
            "みらい"を想像(創造)することにより"いま"がどうあるべきか、未来のために
            <br />
            今なにができるか、みなさまとご一緒に考えていきたいと思います。
          </p>
          <p>
            2本の木、「いまの木・みらいの木」は、みなさまの想いが詰まった花や、
            <br />
            実をつけ成長します。
          </p>
          <p>ぜひご一緒に「いまの木・みらいの木」を育てていきましょう!</p>
        </div>
      </div>
    </div>
  );
}
