import React from 'react';
import style from '../css/web.module.css';
import treeXs_Mirai from '../img/tree-mirai-xs.png';
import treeS_Mirai from '../img/tree-mirai-s.png';
import treeM_Mirai from '../img/tree-mirai-m.png';
import treeXl_Mirai from '../img/tree-mirai-xl.png';
import treeXs_Ima from '../img/tree-ima-xs.png';
import treeS_Ima from '../img/tree-ima-s.png';
import treeM_Ima from '../img/tree-ima-m.png';
import treeXl_Ima from '../img/tree-ima-xl.png';

// tree 成長
const num_xs = 5;
const num_s = 10;
const num_m = 15;

export default function Tree(props) {
  let treeXs, treeS, treeM, treeXl;

  if (props.pageId === 'ima') {
    treeXs = treeXs_Ima;
    treeS = treeS_Ima;
    treeM = treeM_Ima;
    treeXl = treeXl_Ima;
  } else {
    treeXs = treeXs_Mirai;
    treeS = treeS_Mirai;
    treeM = treeM_Mirai;
    treeXl = treeXl_Mirai;
  }

  let src = treeXs;
  let treeStyles = `${style.tree} ${style.size_xs} ${style.fadeIn}`;

  if (!props.post) {
    return <img alt={props.pageTitle} src={src} className={treeStyles} />;
  }

  let t = props.post.total;

  if (t <= num_xs) {
    src = treeXs;
    treeStyles = `${style.tree} ${style.size_xs} ${style.fadeIn}`;
  } else if (t > num_xs && t <= num_s) {
    src = treeS;
    treeStyles = `${style.tree} ${style.size_s} ${style.fadeIn}`;
  } else if (t > num_s && t <= num_m) {
    src = treeM;
    treeStyles = `${style.tree} ${style.size_m} ${style.fadeIn}`;
  } else if (t > num_m) {
    src = treeXl;
    treeStyles = `${style.tree} ${style.size_xl} ${style.fadeIn}`;
  }

  return <img alt={props.pageTitle} src={src} className={treeStyles} />;
}
