import React from 'react';
import style from '../css/web.module.css';

export default function OpenBtn(props) {
  return (
    <button
      onClick={props.openModal}
      className={`${style.open_btn} ${style[`${props.pageId}`]}`}
    >
      {props.pageTitle}にメッセージ投稿
    </button>
  );
}
